import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.number.constructor.js";
import SearchSelect from "../../components/common/Search";
import { uploadFile } from '../../api/qiniuApI';
export default {
  name: "Sector",
  data: function data() {
    return {
      //树形结构数据
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      currentTree: this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {},
      //左边菜单是否折叠
      isCollapse: false,
      //树形结构数据
      treeData: [],
      // 筛选
      filter: [{
        placeholder: '板块状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '板块名称',
        value: '',
        type: 'text',
        list: []
      }],
      sum: "",
      //类型循环的所有id加起来

      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数

      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        name: "",
        client: [],
        rank: 1,
        state: 1,
        isshow: 1,
        icon: null
      },
      // 1、教师端 2、学生端 3、公用
      clientList: [{
        label: '教师端',
        value: 1
      }, {
        label: '学生端',
        value: 2
      }
      // { label: '公用', value: 3 },
      ],

      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {
    '$route': 'changeRouter'
  },
  computed: {
    filtereClient: function filtereClient() {
      // 1、教师端 2、学生端 3、公用
      function formatClient(client) {
        return client == 1 ? '教师端' : client == 2 ? '学生端' : '公用';
      }
      return formatClient;
    }
  },
  components: {
    SearchSelect: SearchSelect
  },
  created: function created() {
    //获取树状菜单
    this.sectorGetAll();
    //获取数据
    this.initData();
  },
  methods: {
    //给其他组件使用的设置当前的tree
    setCurrentTree: function setCurrentTree(value) {
      this.currentTree = value;
    },
    //获取树状结构
    sectorGetAll: function sectorGetAll() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'sectorGetAll',
        data: {},
        sucFn: function sucFn(res) {
          // this.resourcetypeDrop=res.data;
          var allTree = [{
            id: 0,
            name: "所有板块",
            children: res.data
          }];
          _this2.treeData = allTree;
          if (!_this2.$route.query.tree) {
            _this2.currentTree = _this2.treeData[0];
            _this2.changeRouter(_this2.currentTree);
          } else {
            // this.treeData=res.data;
            _this2.currentTree = JSON.parse(_this2.$route.query.tree);
            _this2.changeRouter();
          }
          _this2.$nextTick(function () {
            _this2.$refs.tree.setCurrentKey(_this2.currentTree.id);
          });
        }
      });
    },
    //路由改变执行的方法
    changeRouter: function changeRouter(json) {
      //再重新设置路由
      // this.$emit('navigation',this.navData);

      if (!json) {
        this.currentTree = this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {};
      }
      this.drawerData.pId = this.currentTree.id;
      // console.log("路由改变后的tree",this.currentTree)
      this.filter[1].value = this.$route.query.name || "";
      this.filter[0].value = this.$route.query.state || "";
      this.filterJson = {
        name: this.filter[1].value,
        state: this.filter[0].value
      };
      // this.tableData = []
      this.initData();
    },
    // 初始化数据
    initData: function initData() {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: "sectorGetList",
        data: {
          sectionId: this.currentTree.id,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.tableData = res.data.data;
            _this3.total = res.data.total;
            // this.initType();
          } else {
            _this3.$message.error(res.message);
          }
        }
      });
    },
    //筛选功能
    search: function search(filterArr) {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: "sectorGetList",
        data: {
          name: filterArr[1].value,
          state: filterArr[0].value,
          sectionId: this.currentTree.id,
          // userId:this.userInfo.userId,
          // roleId:this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.tableData = res.data.data;
            _this4.total = res.data.total;
            // this.initType();
          } else {
            _this4.$message.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    //点击展开收起
    isCollapseFn: function isCollapseFn() {
      if (this.isCollapse) {
        this.isCollapse = false;
      } else {
        //展开
        this.isCollapse = true;
      }
    },
    //点击某一个节点
    handleNodeClick: function handleNodeClick(data) {
      this.currentTree = _objectSpread({}, data);
      delete this.currentTree.children;

      //删除树状菜单的子级，否则会因为数据太多挂到地址栏超出地址栏的最大字符导致数据不全的问题
      // this.currentTree.courseId=this.$route.query.courseId
      // this.currentTree.pageSize=10;
      // this.currentTree.pageIndex=1;
      // this.currentTree.structureId=this.currentTree.id;

      var queryData = _objectSpread({}, this.$route.query);
      queryData.tree = JSON.stringify(this.currentTree);
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    // 添加函数
    addFun: function addFun() {
      // this.clearcolumnData();
      // this.initType();
      this.drawerState = "add";
      this.drawer = true;
    },
    //新建初始化数据
    clearcolumnData: function clearcolumnData() {
      this.columnData = {
        name: "",
        code: "",
        rank: "",
        state: "",
        type: ""
      };
      // this.initType();
      this.$refs.form.resetFields();
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    validateArray: function validateArray(rule, value, callback) {
      if (value && value.length > 0) {
        callback();
      } else {
        callback(new Error('请选择客户端类型2'));
      }
    },
    //客户端类型改变
    checkedItem: function checkedItem(value) {
      // this.client.length === 2 ? this.drawerData.client = 3 : this.drawerData.client = Number(this.client)
    },
    //修改课程图标
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        _this.drawerData.icon = this.result;
      };
      uploadFile({
        folderName: "section/icon",
        complete: function complete(res, url) {
          _this.drawerData.icon = url;
          window.loading.close();
        }
      }, _this.file);
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.$refs["form"].resetFields();
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var _this5 = this;
      var that = this;
      this.setPostData();
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === "edit") {
            //修改
            url = "editSector";
          } else {
            //添加
            url = "addSector";
          }
          // 处理下数据
          var rqData = Object.assign({}, that.drawerData);
          rqData.client = rqData.client.length === 2 ? 3 : Number(rqData.client);
          rqData.sectionId = _this5.currentTree.id;
          that.service.axiosUtils({
            requestName: url,
            data: rqData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.clearDrawerData();
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.sectorGetAll();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    //传递的参数处理转换
    setPostData: function setPostData() {
      // this.drawerData.type=0;
    },
    initType: function initType() {},
    //新建初始化数据
    clearDrawerData: function clearDrawerData() {
      this.drawerData = {
        name: "",
        client: [],
        rank: 1,
        state: 1,
        isshow: 1,
        icon: null
      };
      this.initType();
      this.$refs.form.resetFields();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this6 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "editSectorStatus",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this7 = this;
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        setTimeout(function () {
          var client = val.client === 3 ? [1, 2] : [val.client];
          _this7.drawerData = {
            id: val.id,
            name: val.name,
            client: client,
            rank: val.rank,
            state: val.state,
            icon: val.icon,
            isshow: val.isshow,
            pId: _this7.currentTree.id
          };
        }, 30);
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this8 = this;
      //删除当前的板块
      this.service.axiosUtils({
        requestName: "deleteSector",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.$toast.success(res.message);
            _this8.delId = "";
            _this8.centerDialogVisible = false;
            _this8.sectorGetAll();
            _this8.initData();
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    }
  }
};